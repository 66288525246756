.rating-filters{
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
}
.rating-filters-list{
    display: flex;
    gap: 10px;
}
.filter-item{
    padding: 10px 26px;
    color: #8392B9;
    border-radius: 6px;
    background: #FBFBFB;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    transition: .3s;
    cursor: pointer;
    height: fit-content;

    &.active{
        background: var(--mainColor);
        color: #fff;
        font-family: Lato;
        font-size: 16px;
        font-weight: 800;  
    }

    &:hover{
        background: var(--mainColor);
        color: #fff;
    }
}

@media(max-width: 767px){
    .rating-filters{
        flex-direction: column;
        gap: 20px;
    }
    .rating-table{
        display: none;
    }
    .rating-component .outline-button-main{
        display: flex;
    }
}