.filter-select{
    display: flex;
    gap: 10px;
    position: relative;
    overflow: hidden;
    width: 263px;
    .filter-select-item{
        width: 165px;
        border-radius: 6px;
        background: #053A71;
        color: #FFF;
        font-family: Lato;
        font-size: 16px;
        font-weight: 800;
        padding: 10px 16px;
        text-align: center;
        position: absolute;
        right: 0;
        opacity: 0;
        visibility: hidden;

        &.active{
            position: relative;
            opacity: 1;
            visibility: visible;
        }
    }
    .filter-select-button{
        width: 39px;
        height: 39px;
        background: rgba(131, 146, 185, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;

        &::before{
            content: "\e906";
            font-family: 'icomoon' !important;
            color: #8392B9;
        }
    }
    .filter-select-button-next{
        background: var(--mainColor);

        &::before{
            content: "\e907";
            color: #fff;
        }
    }
}

@media(max-width: 767px){
    .filter-select .filter-select-item, .filter-select{
        width: 100%;
    }
}