:root {
    --mainColor:#183F70;
    --footerColor:#F7F7F7;
}

@import "./scss/fonts";
@import "./scss/icomoon";
@import "./scss/main";
@import "./scss/simple/topbar";
@import "./scss/simple/menu";
@import "./scss/complex/footer";
@import "./scss/complex/mainBanner";
@import "./scss/simple/liveComponent";
@import "./scss/complex/articleComponent";
@import "./scss/simple/articleCategories";
@import "./scss/simple/articleCard";
@import "./scss/simple/latestArticleCard";
@import "./scss/complex/ratingComponent";
@import "./scss/simple/ratingFilter";
@import "./scss/ui/filterSelect";
@import "./scss/complex/ratingList";
@import "./scss/complex/ratingGrid";
@import "./scss/complex/partners";
@import "./scss/complex/mapComponent";
@import "./scss/simple/pageTop";
@import "./scss/complex/articlesContainer";
@import "./scss/simple/categories";
@import "./scss/complex/articleDetail";
@import "./scss/complex/documentsPage";
@import "./scss/complex/teamPage";
@import "./scss/ui/search";
@import "./scss/complex/gallery";
@import "./scss/complex/formContact";
@import "./scss/complex/searchContainer";
@import "./scss/complex/pagination";

.loader-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: #b5b3b380;
}
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';
  width: 48px;
  height: 48px;
  border: 2px solid #FFF;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: rotation 3s ease-in-out infinite;
}
.loader::after {
  border-color: #3498db;
  animation-delay: 1.5s;
}
.category-flex{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.text-none{
    text-align: center;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    color: #909090;
    width: 100%;
    padding: 30px 0;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}