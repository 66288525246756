.error{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px 0;
    gap: 10px;
    text-align: center;

    h1{
        color: var(--mainColor);
        font-family: Lato;
        font-size: 96px;
        font-weight: 800;
    }

    img{
        width: 200px;
    }
}

@media(max-width: 767px){
    .error{
        h1{
            font-size: 66px;
        }
        img{
            width: 150px;
            height: 200px;
        }
    }
}