.rating-list{
    display: grid;
    grid-template-columns: .5fr 1fr;
    gap: 10px;
}
.rating-list-left{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    overflow: hidden;

    .rating-list-item:nth-child(1){
        grid-column: 1/3;
        display: flex;
        img{
            border-radius: 0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            height: 100%;
            width: 178px;
            object-fit: cover;
        }
        .rating-list-item-content{
            border-radius: 0;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;  
        }
        .rating-flex{
            flex-direction: column;
            .number{
                font-size: 96px;
                font-weight: 800;
            }
        }
        .rating-content-title{
            font-size: 16px;
        }
        h4{
            font-size: 18px;
        }
    }
}
.rating-list-item{
    display: grid;
    grid-template-rows: 131px 1fr;
    width: 100%;
    img{
        height: 131px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        object-fit: cover;
    }
}
.rating-list-item-content{
    background: linear-gradient(270deg, #053A71 0%, #1070D3 100%), linear-gradient(30deg, #082161 0%, rgba(37, 96, 249, 0.81) 99.05%);
    color: #fff;
    padding: 9px 15px 20px 15px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;

    h4{
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
    }
    p{
        color: #A7C4E2;
        font-size: 12px;
        margin-top: 8px;
    }
    .rating-flex{
        display: flex;
        gap: 4px;
        margin-bottom: 23px;
    }
    .rating-content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-top: auto;

        .rating-content-title{
            font-weight: 600;
        }
    }
}
@media(max-width: 1250px){
    .rating-list{
        grid-template-columns: 1fr;
    }
}
@media(max-width: 767px){
    .rating-list-left .rating-list-item:nth-child(1) .rating-flex .number{
        font-size: 40px;
    }
    .rating-list-left .rating-list-item:nth-child(1) img{
        height: 100%;
        width: 120px;
    }
    .rating-list-item-content h4{
        font-size: 14px;
    }
}

@media(max-width: 480px){
    .rating-list-left{
        grid-template-columns: 1fr;
    }
    .rating-list-left .rating-list-item:nth-child(1){
        grid-column: 1;
    }
    .rating-list-item{
        display: flex;
        img{
            border-radius: 0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            height: 100%;
            width: 120px;
        }
    }
    .rating-list-item-content{
        border-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;  
    }
}