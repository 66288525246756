.partners{
    padding: 36px 0;
}
.partners-slider{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 60px;
    flex-wrap: wrap;
}
.partners-slider-item{
    //padding: 10px;
    height: 90px;

    img{
        height: 100%;
        object-fit: contain;
    }
}