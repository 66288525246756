.article-container{
    padding: 70px 0 30px;
    background: #FBFBFB;
}
.article-container-grid{
    display: grid;
    grid-template-columns: 1fr .4fr;
    gap: 30px;
}
.centers-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
}
.article-grid-left{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .article-card{
        width: calc((100% - 32px)/3);
        img{
            height: 169px;
        }
        &:nth-child(1){
            width: 100%;

            img{
                height: 344px;
            }
        }
        &:nth-child(2){
            width: calc((100% - 16px)/2);

            img{
                height: 236px;
            }
        }
        &:nth-child(3){
            width: calc((100% - 16px)/2);

            img{
                height: 236px;
            }
        }
    }
}

@media(max-width: 1250px){
    .article-grid .article-card{
        width: calc((100% - 16px)/2);
    }
    .article-grid .article-card:nth-child(1) img, .article-grid .article-card:nth-child(2) img{
        height: 169px;
    }
    .article-container-grid{
        gap: 16px;
    }
    .article-grid-left{
        .article-card{
            width: calc((100% - 16px)/2);
        }
    }
    .article-grid-left .article-card:nth-child(3) img, .article-grid-left .article-card:nth-child(2) img{
        height: 169px;
    }
    .centers-grid{
        grid-template-columns: 1fr 1fr
    }
}
@media(max-width: 916px){
    .article-container{
        padding: 30px 0;

        .categories{
            background: #fff;
            margin-bottom: 20px;
        }
        .latest-articles{
            background: transparent;
            padding: 0;
            border: none;
            box-shadow: none;
        }
    }
    .article-container-grid{
        grid-template-columns: 1fr;
    }
}
@media(max-width: 767px){
    .article-grid .article-card{
        width: calc((100% - 9px)/2);

        &:nth-child(1), &:nth-child(2){
            width: calc((100% - 9px)/2);
        }
    }
    .article-grid .article-card:nth-child(1) img, .article-grid .article-card:nth-child(2) img{
        height: 120px;
    }
    .article-card img{
        height: 120px;
    }
    .article-container-grid{
        grid-template-columns: 1fr;
    }
    .article-container{
        padding: 20px 0;
    }
}
@media(max-width: 540px){
    .article-grid-left .article-card img{
        height: 130px;
    }
    .article-grid-left .article-card:nth-child(1) img{
        height: 240px;
    }
    .article-grid-left .article-card:nth-child(2) img, .article-grid-left .article-card:nth-child(3) img{
        height: 130px;
    }
}
@media(max-width: 400px){
    .article-grid .article-card{
        width: 100%;
    }
    .article-grid .article-card:nth-child(1), .article-grid .article-card:nth-child(2){
        width: 100%;
    }
    .article-grid .article-card:nth-child(1) img, .article-grid .article-card:nth-child(2) img{
        height: 168px;
    }
    .article-card img{
        height: 168px;
    }
    .article-grid-left .article-card, .article-grid-left .article-card:nth-child(3), .article-grid-left .article-card:nth-child(2){
        width: 100%;
    }
    .article-grid-left .article-card:nth-child(2) img, .article-grid-left .article-card:nth-child(3) img, .article-grid-left .article-card img, .article-grid-left .article-card:nth-child(1) img{
        height: 168px;
    }
    .centers-grid{
        grid-template-columns: 1fr;
    }
}