.article-component{
    padding: 36px 0;
    background: #FBFBFB;
}

.article-component-top{
    padding: 14px 0;
    background: url("../../images/dots.png");
}
.article-component-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.article-component-wrapper{
    display: grid;
    grid-template-columns: 1fr .3fr;
    margin-top: 20px;
    gap: 30px;
}
.article-grid{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .article-card{
        width: calc((100% - 32px)/3);
    }

    .article-card:nth-child(1), .article-card:nth-child(2){
        width: calc(50% - 8px);
        img{
            height: 236px;
        }
    }
}
.latest-articles{
    background: #fff;
    padding: 30px;
    height: fit-content;
    border-radius: 6px;
    border-bottom: 4px solid var(--mainColor);
    box-shadow: 0px 15px 16px 3px rgba(0, 0, 0, 0.05);

    h3{
        margin-bottom: 30px;
    }

    &.gray{
        background: #F7F7F7;
    }
}
@media(max-width: 1250px){
    .latest-articles{
        padding: 16px;
    }
}
@media(max-width: 916px){
    .article-component-wrapper{
        grid-template-columns: 1fr;
    }
    .article-left{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
    .latest-articles h3{
        font-size: 20px;
    }
}

@media(max-width: 767px){
    .article-grid{
        gap: 9px;
    }
    .latest-articles{
        padding: 0;
        background: transparent;
        box-shadow: none;
        border: none;
        margin-bottom: 20px;
    }
    .article-left{
        grid-template-columns: 1fr
    }
}