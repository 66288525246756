@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?csp8tw');
  src:  url('../fonts/icomoon/icomoon.eot?csp8tw#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?csp8tw') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?csp8tw') format('woff'),
    url('../fonts/icomoon/icomoon.svg?csp8tw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ri_vk-fill:before {
  content: "\e91b";
}
.icon-mdi_instagram:before {
  content: "\e91c";
}
.icon-mdi_twitter:before {
  content: "\e91d";
}
.icon-clock:before {
  content: "\e91a";
}
.icon-arrow-right2:before {
  content: "\e919";
}
.icon-arrow-left:before {
  content: "\e918";
}
.icon-burger:before {
  content: "\e916";
}
.icon-close:before {
  content: "\e917";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-youtube:before {
  content: "\e911";
}
.icon-telegram:before {
  content: "\e912";
}
.icon-whatsapp:before {
  content: "\e913";
}
.icon-document:before {
  content: "\e914";
}
.icon-arrow-right:before {
  content: "\e915";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-image:before {
  content: "\e902";
}
.icon-download:before {
  content: "\e903";
}
.icon-eyes:before {
  content: "\e904";
}
.icon-calendar:before {
  content: "\e905";
}
.icon-chevron-left:before {
  content: "\e906";
}
.icon-chevron-right:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-vk:before {
  content: "\e90a";
}
.icon-tiktok:before {
  content: "\e90b";
}
.icon-chevron-down:before {
  content: "\e90c";
}
.icon-sms:before {
  content: "\e90d";
}
.icon-phone:before {
  content: "\e90e";
}
.icon-google:before {
  content: "\e90f";
}
.icon-pinterest:before {
  content: "\e910";
}