.article-card{
    background: #fff;
    border-radius: 6px;
    border-bottom: 4px solid #053A71;
    transition: .3s;

    img{
        height: 169px;
        width: 100%;
        object-fit: cover;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: .3s;
        background: #ECEFF2;
    }

    .linear-gradient{
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(39deg, rgba(60, 171, 225, 0.90) 0.77%, rgba(60, 171, 225, 0.00) 132.8%);
        transition: .3s;
        opacity: 0;
    }

    &:hover{
        border-bottom: 4px solid #3CABE1;

        h5{
            color: var(--mainColor);
        }
        .linear-gradient{
            opacity: 1;
        }
    }
}
.position-relative{
    position: relative;
}
.article-card-wrapper{
    padding: 16px 20px 20px;
    h5{
        margin-bottom: 12px;
        transition: .3s;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
        display: -webkit-box;
    }
    p{
        color: #696868;
        line-height: 123.9%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
    }
}
.date{
    color: rgba(38, 38, 38, 0.60);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
    i{
        margin-right: 6px;
    }
}

@media(max-width: 767px){
    .article-card-wrapper{
        padding: 10px;
        h5{
            font-size: 14px;
            color: #262626;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
            display: -webkit-box;
        }
        p{
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 4;
            display: -webkit-box; 
        }
    }
}