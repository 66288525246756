.footer{
    background: var(--footerColor);
    padding: 39px 0;
    margin-top: auto;
}
.footer-logo{
    img{
        height: 79px;
        object-fit: contain;
        width: fit-content;
    }
}
.footer-text{
    margin-bottom: 10px;
    color: #94A3B8;
}
.footer-socials{
    font-size: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    a{
        color: var(--mainColor);
    }
}
.footer-data{
    margin-bottom: 10px;
    a{
        color: #94A3B8;
    }
}
.footer-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.footer-nav{
    display: flex;
    flex-direction: column;
    gap: 24px;
    a{
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        color: #053A71;
        transition: .3s;

        &:hover{
            color: #2563EB;
        }
    }
}
.footer-bottom{
    width: 100%;
    border-top: 1px solid #E2E8F0;
    margin-top: 63px;
    padding-top: 63px;
    text-align: center;
}
.footer-submit{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.footer-title{
    color: #94A3B8;
    font-family: Lato;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 18px;
}

@media(max-width: 916px){
    .footer-wrapper{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 14px;
    }
    .footer-logo{
        grid-column: 1/4;
    }
}

@media(max-width: 767px){
    .footer-logo img{
        height: 50px;
    }
    .footer-logo{
        grid-column: 1/3;
        justify-self: center;
    }
    .footer-wrapper{
        grid-template-columns: 1fr 1fr;
    }
    .footer-submit{
        grid-column: 1/3;
        margin-top: 20px;
    }
    .footer-bottom{
        margin-top: 33px;
        padding-top: 33px;
    }
}

@media(max-width: 320px){
    .footer-logo,  .footer-submit{
        grid-column: 1;
    }
    .footer-wrapper{
        grid-template-columns: 1fr;
    }
}