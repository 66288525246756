.article-categories{
    display: flex;
    gap: 16px;
    margin-top: 30px;
    flex-wrap: wrap;
}
.article-categories-item{
    color: #262626;
    font-family: Lato;
    padding: 8px 17px;
    border-radius: 30px;
    transition: .3s;

    &:hover{
        background: var(--mainColor);
        color: #fff;
    }

    &.active{
        background: var(--mainColor);
        color: #fff;
    }
}
