.search{
    background: #fff;
    display: flex;
    height: 65px;
    align-items: center;

    &.active{
        position: absolute;
        width: 100%;

        .search-input{
            width: 100%;
        }
    }
}
.search-input{
    color: #999;
    font-size: 14px;
    border: none;
    outline: none;
    width: 0;
}
.icon-search{
    font-size: 20px;
    color: var(--mainColor);
    margin-right: 10px;
    cursor: pointer;
}
.icon-close{
    color: #999999;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
}