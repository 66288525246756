.sample-detail-grid{
    display: grid;
    grid-template-columns: 1fr .4fr;
    padding: 70px 0;
    gap: 40px;
}
.form-contact{
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-top: 20px;
}
.input-df{
    height: 55px;
    padding: 0 21px;
    border-radius: 6px;
    border: 1px solid #EDEDED;
    background: #EDEDED;
    width: 100%;
    outline: none;
    font-size: 16px;
    transition: .3s;

    &.hover, &:focus{
        border: 1px solid var(--mainColor); 
    }
}
.textarea-df{
    border-radius: 6px;
    border: 1px solid #EDEDED;
    background: #EDEDED;
    outline: none;
    height: 120px;
    padding: 10px 21px;
    font-size: 16px;
    transition: .3s;

    &.hover, &:focus{
        border: 1px solid var(--mainColor); 
    }
}
.d-flex{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    span{
        font-weight: 700;
        color: #151515;
    }

    h3{
        margin-bottom: 10px;
        margin-top: 20px;
    }
    p{
        margin-bottom: 30px;
    }
    i{
        margin-right: 6px;
        color: #A6A6A6;
        font-size: 12px;
    }
}
@media(max-width: 916px){
    .sample-detail-grid{
        grid-template-columns: 1fr;
        padding: 30px 0;

        .latest-articles{
            padding: 0;
            background: transparent;
            box-shadow: none;
            border: none;
        }
    }
    .category-mob-none{
        .categories{
            display: none !important;
        }
        .categories-mob{
            display: block !important;
        }
    }
}

@media(max-width: 767px){
    .sample-detail-grid{
        padding: 30px 0;
        gap: 20px;
    }
}