* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
    text-decoration: none;
}
html{
    scroll-behavior: smooth;
}
body {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}
#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
img{
    width: 100%;
    max-width: 100%;
}
ul, li{
    padding: 0;
    margin: 0;
    list-style-position: inside;
}
.container{
    max-width: 1248px;
    padding: 0 24px;
    width: 100%;
    margin: 0 auto;
}
.input{
    border-radius: 6px;
    border: 1px solid #E4E4E7;
    background: #FFF;
    height: 55px;
    padding: 0 16px;
    outline: none;
    font-size: 16px;
    transition: .3s;

    &:hover, &:focus{
        border: 1px solid var(--mainColor); 
    }
}
.main-button{
    height: 55px;
    padding: 0 16px;
    border-radius: 6px;
    background: #053A71;
    color: #fff;
    outline: none;
    border: none;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s;

    &:hover{
        background: #002143;
    }
}

.outline-button{
    padding: 0 21px;
    height: 41px;
    border-radius: 4px;
    border: 1px solid #FFF;
    outline: none;
    background: transparent;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s;

    &:hover{
        background: #fff;
        color: #CF1616;

        a{
            color: #CF1616;
        }
    }

    a{
        color: #fff;
    }
}
.outline-button-main{
    padding: 0 21px;
    width: 100%;
    height: 41px;
    border-radius: 4px;
    border: 1px solid var(--mainColor);
    outline: none;
    background: transparent;
    color: var(--mainColor);
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.white-button{
    padding: 0 12px;
    background: #fff;
    color: #CF1616;
    font-size: 12px;
    text-transform: uppercase;
    height: 23px;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 6px;
}
h1{
    color: #FFF;
    font-family: Bebas Neue;
    font-size: 76px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.05;

    &.mini{
        font-family: Lato;
        font-size: 36px;
        font-weight: 800;
        text-transform: uppercase;
    }
}
h2{
    color: #262626;
    font-family: Lato;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
}
h3{
    color: #000;
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
}
h4{
    color: #FFF;
    font-family: Lato;
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
}
h5{
    color: #262626;
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 136.9%; 
}
.link{
    color: #053A71;
    font-family: Lato;
    font-size: 16px;
}
.mb-2{
    margin-bottom: 20px;
}

.page-loader{
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    background: rgba(109, 109, 109, 0.199);
}

.loader-circle {
    width: 48px;
    height: 48px;
    border: 5px solid var(--mainColor);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@media(max-width: 1250px){
    .container{
        max-width: 916px;
        padding: 0 16px;
    }
    h1{
        font-size: 58px;
    }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 20px;
    }
}

@media(max-width: 916px){
    .container{
        max-width: 768px;
    }
    h1{
        font-size: 48px;
    }
}

@media(max-width: 767px){
    .container{
        max-width: 100%;
    }
    h1{
        &.mini{
            font-size: 24px;
        }
    }
    h4{
        font-size: 14px;
    }
    h2{
        font-size: 16px;
    }
}

@media(max-width: 480px){
    h1{
        font-size: 36px;
        &.mini{
            font-size: 20px;
        }
    }
}