.team-page{
    .team-h3{
        margin-top: 50px;
        font-size: 32px;
    }
}
.team-detail-grid{
    padding: 40px 0 70px;
    display: grid;
    grid-template-columns: 1fr .4fr;
    gap: 75px;
}
.team-card{
    background: #F7F7F7;
    text-align: center;
    border-radius: 6px;
    width: calc((100% - 80px)/3);
    height: fit-content;

    img{
        height: 300px;
        object-fit: cover;
        border-radius: 6px;
    }

    h4{
        color: #262626;
        font-size: 20px;
        text-transform: capitalize;
        margin-bottom: 10px;
        font-weight: 600;
    }
    span{
        font-size: 16px;
        color: var(--mainColor);
    }
}
.managment-page{
    .team-card{
        background: none;
    }
}
.team-card-content{
    padding: 20px 6px 13px;
}
.team-grid{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    height: fit-content;
}

@media(max-width: 1250px){
    .team-detail-grid{
        gap: 30px;
    }
    .team-grid{
        gap: 40px;
    }
    .team-card{
        width: calc((100% - 40px)/2);
    }
}
@media(max-width: 916px){
    .team-page .team-h3{
        font-size: 26px;
    }
    .team-detail-grid{
        grid-template-columns: 1fr;
        padding: 30px 0;
    }
    .team-page{
        .latest-articles{
            background: transparent;
            box-shadow: none;
            border: none;
            padding: 0;
        }
    }
    .team-grid{
        gap: 20px;
    }
    .team-card{
        width: calc((100% - 40px)/3);
    }
}
@media(max-width: 767px){
    .team-page .team-h3{
        font-size: 22px;
        margin-top: 30px;
    }
    .team-card{
        width: calc((100% - 20px)/2);
    }
}

@media(max-width: 420px){
    .team-card{
        width: 100%;

        img{
            height: auto;
        }
    }
}