@font-face {
    font-family: "Bebas Neue";
    src: url("../fonts/bebas/BebasNeue_Bold.otf");
    src: url("../fonts/bebas/BebasNeue_Bold.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Bebas Neue";
    src: url("../fonts/bebas/BebasNeue_Regular.otf");
    src: url("../fonts/bebas/BebasNeue_Regular.otf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-Bold.ttf");
    src: url("../fonts/lato/Lato-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-ExtraBold.ttf");
    src: url("../fonts/lato/Lato-ExtraBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-Medium.ttf");
    src: url("../fonts/lato/Lato-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-Regular.ttf");
    src: url("../fonts/lato/Lato-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-SemiBold.ttf");
    src: url("../fonts/lato/Lato-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-Bold.ttf");
    src: url("../fonts/inter/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-ExtraBold.ttf");
    src: url("../fonts/inter/Inter-ExtraBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-Medium.ttf");
    src: url("../fonts/inter/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-Regular.ttf");
    src: url("../fonts/inter/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-SemiBold.ttf");
    src: url("../fonts/inter/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
