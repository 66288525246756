.live-component{
    background: linear-gradient(102deg, #E91C1C -6.7%, #900808 116.08%), #FFF;
    padding: 30px 0;
}
.live-component-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .white-button{
        margin-bottom: 9px;
    }
}

@media(max-width: 916px){
    .live-component{
        padding: 20px 0;
    }
    .live-component-wrapper{
        flex-direction: column;
        align-items: start;
        gap: 9px;
    }
}