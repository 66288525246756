.calendar{
    border-radius: 6px;
    border-bottom: 4px solid var(--mainColor);
    background: #FFF;
    box-shadow: 0px 15px 16px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 13px;
    padding: 30px;
    height: fit-content;
    width: fit-content;

    h3{
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
    }
}
.calendar-item{
    border-radius: 6px;
    background: #F9F9F9;
    padding: 8px 9px;
    p{
        margin-bottom: 10px;
        font-size: 16px;
        color: #181823;
    }
    ul{
        list-style-position: inside;
        padding-left: 0;
        li{
            font-family: Lato;
            color: var(--mainColor);

            a{
                font-family: Lato;
                color: var(--mainColor);
            }
            span{
                color: #DA1919;
            }

            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}
.react-datepicker{
    border: none;
    .react-datepicker__month-container{
        background: #fff;
    }
    .react-datepicker__header {
        background: #fff;
        border: none;
        border-bottom: 1px solid #E4E5E7;

        .react-datepicker__current-month{
            color: var(--mainColor);
            font-family: Lato;
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.16px;
            text-transform: capitalize;
        }
    }
    .react-datepicker__navigation-icon--next::before, .react-datepicker__navigation-icon--previous::before {
        height: 6px;
        width: 6px;
    }
    .react-datepicker__day-name{
        color: #7E818C;
        text-transform: uppercase;
        font-size: 10px;
    }
    .react-datepicker__day{
        color: #181823;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        width: 36px;
    }
    .react-datepicker__day--selected{
        color: #FDFDFD;
        background: var(--mainColor);
        border-radius: 30px;
        font-weight: 400;
    }
    .react-datepicker__day:hover{
        border-radius: 30px;
    }
    .react-datepicker__day--keyboard-selected{
        border-radius: 30px;
    }
    .react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted{
        color: #FDFDFD;
        background: var(--mainColor);
        border-radius: 30px;
        font-weight: 400;
    }
}