.topbar{
    background: var(--mainColor);
    width: 100%;
}
.topbar-wrapper{
    justify-content: end;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 42px;
    color: #fff;

    a{
        color: #fff;
        font-size: 16px;
    }
}
.topbar-socials{
    display: flex;
    gap: 16px;
}
.languages{
    display: flex;
    font-weight: 500;

    span{
        cursor: pointer;
        opacity: .5;
        transition: .3s;

        &:hover{
            opacity: 1;
        }
        &.active{
            opacity: 1;
        }
        &:not(:last-child){
            margin-right: 7px;
            border-right: 2px solid #ffffff85;
            padding-right: 7px;
        }
    }
}

@media(max-width: 767px){
    .topbar-wrapper{
        display: none;
    }
}