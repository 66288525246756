.article-detail-top{
    width: 100%;
    background-blend-mode: multiply;
    height: 500px;
    padding: 85px 0;
    display: flex;
    align-items: end;
    color: #fff;

    h1{
        color: #fff;
        font-family: Lato;
        font-size: 40px;
        font-weight: 400;
        margin-bottom: 30px;
    }
}
.article-category{
    padding: 6px 14px;
    border-radius: 6px;
    background: #009BDC;
    text-transform: uppercase;
    width: fit-content;
    margin-bottom: 20px;
    color: #fff;
}
.article-detail-list{
    display: flex;
    gap: 19px;
    align-items: center;

    i{
        margin-right: 8px;
    }
}
.line{
    background:#DCDCDC;
    width: 2px;
    height: 14px;
}
.article-detail-grid{
    padding: 70px 0;
    display: grid;
    grid-template-columns: 1fr .4fr;
    gap: 75px;
}
.description{
    color: #444;
    font-size: 16px;
    //margin-bottom: 35px;

    p{
        margin-bottom: 10px;
    }
    h4{
        color: #262626;
        margin-bottom: 20px;
    }
}

.share-block{
    padding: 20px;
    background: #F8F8F8;
    box-shadow: 0px 1px 0px 0px rgba(211, 211, 211, 0.75);
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #444;
    font-size: 16px;
    font-weight: 500;
}
.share-block-wrapper{
    display: flex;
    gap: 3px;
}
.tags{
    display: flex;
    gap: 3px;
    margin-top: 35px;
    flex-wrap: wrap;
}
.tags-item{
    border-radius: 6px;
    border: 1px solid #DCDCDC;
    padding: 6px 11px;
    color: #444;
    font-size: 13px;

    &.active-first{
        color: #fff;
        border: 1px solid var(--mainColor);
        background: var(--mainColor);
    }
}

.article-next{
    display: flex;
    margin-top: 35px;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
}
.article-next-hr{
    height: 100px;
    width: 1px;
    background: #DCDCDC;
}

.article-next-item{
    cursor: pointer;
    .next-icon{
        font-size: 16px;
        color: #444;

        i{
            color: #0EA8FF;
            margin-left: 10px;
        }
    }
    .prev-icon{
        i{
            margin-right: 10px;
            margin-left: 0;
        }
    }
    p{
        color: #111;
        font-family: Lato;
        font-size: 16px;
        margin-top: 9px;
        max-width: 300px;
        font-weight: 500;
    }
    &.end{
        text-align: end;
    }
}
@media(max-width: 1250px){
    .article-detail-grid{
        gap: 40px;
    }
}

@media(max-width: 916px){
    .article-detail-grid{
        grid-template-columns: 1fr;
        padding: 40px 0;
        .categories{
            display: none;
        }
        .latest-articles{
            background: transparent;
            box-shadow: none;
            border: none;
            padding: 0;
        }
    }
    .article-detail-top{
        h1{
            font-size: 30px;
        }
    }
    .article-next-item p{
        display: none;
    }
    .article-next-hr{
        height: 50px;
    }
    .article-category{
        font-size: 11px;
    }
    .article-detail{
        .latest-articles{
            background: transparent;
            padding: 0;
            box-shadow: none;
            border: none;
        }
    }
}
@media(max-width: 767px){
    .article-detail-top{
        height: 400px;
        padding-bottom: 20px;
        h1{
            font-size: 20px;
            text-transform: capitalize;
        }
    }
    .article-detail-grid{
        padding: 20px 0;
    }
}

@media(max-width: 480px){
    .article-detail-top{
        height: 335px;
    }
}