.page-top{
    background-repeat: no-repeat;
    background-image: url("../../images/pagetop.png");
    padding: 87px 0;
    background-position: center;
    ul{
        list-style: none;
        display: flex;
        gap: 10px;
        margin-top: 16px;
        li{
            color: #fff;

            a{
                color: #728A92;
            }
            i{
                font-size: 12px;
                color: #728A92;
            }
        }
    }
}

@media(max-width: 767px){
    .page-top{
        padding: 48px 0;
    }
}