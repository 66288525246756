.latest-article-card{
    display: flex;
    gap: 17px;

    &:not(:last-child){
        margin-bottom: 20px;
    }
    img{
        width: 92px;
        height: 92px;
        min-width: 92px;
        border-radius: 6px;
        object-fit: cover;
        background: #ECEFF2;
    }

    &:hover{
        p{
            color: var(--mainColor);
        }
    }
}
.latest-article-content{
    display: flex;
    flex-direction: column;
    p{
        color: #262626;
        font-family: Lato;
        line-height: 136.9%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
        transition: .3s;
    }
    .date{
        margin-top: auto;
        margin-bottom: 0;
    }
}

@media(max-width: 1250px){
    .latest-article-card {
        gap: 6px;
        img{
            width: 70px;
            height: 70px;
        }
    }
}