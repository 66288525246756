.main-banner{
    height: 822px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    max-width: 100%;
    h1{
        max-width: 468px;
    }
    img{
        position: absolute;
        height: 822px;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
    }
}
.main-banner-wrapper{
    position: relative;
    z-index: 1;
}

@media(max-width: 1250px){
    .main-banner{
        height: 600px;
        img{
            height: 600px;
        }
    }
}

@media(max-width: 767px){
    .main-banner{
        height: 500px;
        img{
            height: 600px;
        }
    }
    .main-banner h1{
        max-width: 360px;
    }
}