.gallery{

}

.gallery-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    overflow: hidden;
    max-width: 100%;
}

.gallery-image{
    height: 230px;
    &:nth-child(1){
        grid-column: 1/3;
        grid-row: 1/3;
        height: 514px;

        img{
            height: 514px;
        }
    }
    &:nth-child(2), &:nth-child(3){
        height: 250px;
        img{
            height: 250px;
        }
    }
    img{
        height: 230px;
        object-fit: cover;
        border-radius: 6px;
    }
}

@media(max-width: 1250px){
    .gallery-image{
        height: 200px;
        img{
            height: 200px;
        }
        &:nth-child(1) {
            height: 414px;
            img{
                height: 414px;
            }
        }
        &:nth-child(3) img, &:nth-child(2) img{
            height: 200px;
        }
        &:nth-child(2), &:nth-child(3){
            height: 200px;
        }
    }
}

@media(max-width: 767px){
    .gallery-image{
        height: 180px;
        img{
            height: 180px;
        }
        &:nth-child(1) {
            height: 374px;
            img{
                height: 374px;
            }
        }
        &:nth-child(3) img, &:nth-child(2) img{
            height: 180px;
        }
        &:nth-child(2), &:nth-child(3){
            height: 180px;
        }
    }
}
@media(max-width: 540px){
    .gallery-grid{
        grid-template-columns: 1fr 1fr;
        gap: 11px;
    }
    .gallery-image{
        height: 162px;
        img{
            height: 162px;
        }
        &:nth-child(1) {
            height: 335px;
            img{
                height: 335px;
            }
        }
        &:nth-child(3) img, &:nth-child(2) img{
            height: 162px;
        }
        &:nth-child(2), &:nth-child(3){
            height: 162px;
        }
    }
}