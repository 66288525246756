.map-component{
    padding: 36px 0;
    background: url("../../images/background.png");

    h2{
        color: #fff;
    }

    svg{
        width: 100%;
        height: auto;
        transition: .5s;

        path:hover{
            fill: #D8EEF9;
        }
    }
}
.map-content{
    margin-top: 36px;
    display: flex;
    gap: 30px;
    overflow: hidden;
    position: relative;
}
.center-text{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #909090;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    height: 80%;
}
.map-list{
    padding: 10px;
    border-radius: 16px;
    background: #FBFBFB;
    transform: translateX(100vw);
    transition: .5s;
    position: absolute;
    right: 0;
    width: 340px;

    .icon-close{
        font-size: 18px;
        position: absolute;
        right: 10px;
        transition: .3s;
        cursor: pointer;
        z-index: 1;

        &:hover{
            color: red;
        }
    }
    h4{
        font-weight: 600;
        color: var(--mainColor);
        text-transform: capitalize;
        margin: 20px 0 30px;
        text-align: center;
    }
    &.active{
        transform: translateX(0);
    }
}
.overflow-content{
    max-height: 390px;
    overflow-y: auto;
    height: 390px;
    &::-webkit-scrollbar {
        width: 5px;
        opacity: 0;
        position: absolute;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--mainColor);
        border-radius: 4px;
        border: 1px solid var(--mainColor);
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
}
.region-card{
    display: flex;
    gap: 10px;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 15px 16px 3px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: .3s;

    &:hover{
        box-shadow: 0px 0px 11.6px 2px rgba(60, 171, 225, 0.50);
    }

    &:not(:last-child){
        margin-bottom: 13px;
    }

    .region-card-content{
        padding: 8px 8px 8px 0;
        display: flex;
        flex-direction: column;
    }
    .date{
        margin-top: auto;
        margin-bottom: 0;
    }
    img{
        width: 87px;
        object-fit: cover;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    p{
        font-size: 16px;
        color: #181823;
        font-weight: 500;
        margin-bottom: 8px;
    }
    .address{
        color: var(--mainColor);
        margin-bottom: 8px;
        font-size: 12px;
    }
}

@media(max-width: 1250px){
    .map-content{
        flex-direction: column;
    }
    .map-list.active{
        position: relative;
    }
}

@media(max-width: 916px){
    .map-content{
        svg{
            width: 100%;
            height: auto;
        }
    }
}