.menu{
    background: #fff;
    width: 100%;

    &.sticky{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;

        .menu-wrapper{
            height: 55px;
        }
        .menu-wrapper-flex{
            height: 55px;
        }
        .menu-nav a{
            padding: 19px 20px;
        }
        .dropdown-link a{
            padding-right: 34px;
        }
        .dropdown-link{
            padding: 19px 0;
        }
        .dropdown-list{
            top: 54px;
        }
        .search{
            height: 55px;
        }
    }
}
.menu-wrapper{
    height: 65px;
    display: flex;
    align-items: center;
    gap: 65px;
    position: relative;
}
.menu-wrapper-flex{
    height: 65px;
    display: flex;
    align-items: center;
    gap: 65px;
    position: relative; 
    width: 100%;
}
.logo{
    img{
        height: 48px;
        object-fit: contain;
    }
}
.menu-nav{
    display: flex;
    height: 100%;
    //gap: 40px;
    margin-left: auto;
    a{
        color: var(--mainColor);
        text-transform: uppercase;
        font-weight: 500;
        padding: 24px 20px;
        transition: .3s;

        &:hover{
            background: #E8ECF1;
        }
    }
}
.dropdown-link{
    position: relative;
    cursor: pointer;
    padding: 24px 0;
    transition: .3s;

    a{
        padding-right: 34px;
    }

    &:hover{
        background: #E8ECF1;
        .dropdown-list{
            opacity: 1;
            visibility: visible;
        }
    }

    &::after{
        position: absolute;
        content: "\e90c";
        font-family: 'icomoon' !important;
        font-size: 9px;
        color: var(--mainColor);
        right: 14px;
        top: 44%;
    }
}
.dropdown-list{
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #E8ECF1;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    z-index: 10;
    padding: 30px 20px;
    border-radius: 0px 0px 6px 6px;
    border-bottom: 4px solid #183F70;
    box-shadow: 0px 15px 16px 3px rgba(0, 0, 0, 0.05);
    top: 64px;
    width: 300px;

    a{
        padding: 12px 16px;
        color: #262626;
        font-family: Lato;
        font-size: 20px;
        text-transform: capitalize;
        font-weight: 400;
        border-radius: 6px;
        transition: .3s;

        &:hover{
            background: #FFF;
            color: var(--mainColor);   
        }
        &.active{
            background: #FFF;
            color: var(--mainColor);
        }
    }
}
.menu-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    display: none;

    .icon-close{
        font-size: 26px;
    }

    img{
        height: 50px;
        object-fit: contain;
        width: fit-content;
    }
}
.menu-bottom{
    margin-top: auto;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    display: none;
}
.socials{
    display: flex;
    gap: 20px;
    i{
        color: var(--mainColor);
        font-size: 24px;
    }
}
.languages-menu{
    font-size: 16px;
    color: var(--mainColor);
    font-weight: 600;
    display: flex;
    gap: 10px;
    color: #7D7D7D;

    span{
        height: 20px;
        width: 1px;
        background: #7D7D7D;
    }
    .item-active{
        color: var(--mainColor);
    }
}
.icon-burger{
    font-size: 24px;
    color: var(--mainColor);
    cursor: pointer;
}
.burger-none{
    display: none;
}
.logo-img, .burger-none{
    position: relative;
    z-index: 1;
}
.logo-img{
    min-width: 120px;
    img{
        height: 50px;
        object-fit: contain;
    }
}
@media(max-width: 1250px){
    .menu-wrapper{
        gap: 20px;
    }
    .menu-wrapper-flex{
        gap: 20px;
    }
    .menu-nav a{
        padding: 24px 10px;
    }
    .dropdown-link::after{
        right: -3px;
        font-size: 7px;
    }
    .dropdown-list a{
        padding: 8px 12px;
        font-size: 14px;
    }
    .dropdown-list{
        padding: 15px 10px;
        width: 200px;
    }
}
@media(max-width: 916px){
    .burger-none{
        display: flex;
    }
    .menu-nav{
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        display: flex;
        flex-direction: column;
        padding: 20px;
        transform: translateX(-100%);
        transition: .3s;
        max-height: 100vh;
        overflow-y: auto;

        &.active{
            transform: translateX(0);
            

            a:hover, .dropdown-link:hover{
                background: none;
                color: #2563EB;
            }
        }
    }
    .menu-top, .menu-bottom{
        display: flex;
    }
    .menu-wrapper-flex{
        position: absolute;
        &.active{
            z-index: 1;
        }
    }
    .logo-img{
        margin: 0 auto;
    }
    .menu-wrapper{
        gap: 0;
        padding-right: 24px;
    }
    .search{
        margin-left: auto;
    }
    .dropdown-link{
        padding: 14px 0;
    }
    .menu-nav a{
        padding: 14px 0;
    }
    .dropdown-link{
        padding-bottom: 0;
    }
    .dropdown-link .dropdown-list{
        opacity: 1;
        visibility: visible;
        position: relative;
        z-index: 1;
        top: 0;
        box-shadow: none;
        border: none;
        background: none;
        padding: 0 10px;
    }
    .dropdown-link::after{
        top: 10px;
    }
}