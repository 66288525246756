.search-container{
    .flex{
        display: flex;
        gap: 20px;
        margin-bottom: 34px;

        .main-button{
            height: 51px;
            font-size: 14px;
            padding: 0 46px;
            font-weight: 700;
        }
    }
}
.search-page{
    background: #FBFBFB;

    .latest-articles{
        margin-bottom: 13px;
    }
}
.input-search{
    outline: none;
    border: none;
    border-radius: 6px;
    border-bottom: 4px solid var(--mainColor);
    background: #FFF;
    height: 51px;
    width: 100%;
    padding: 0 27px;
}
.search-card{
    border-radius: 6px;
    border-bottom: 4px solid var(--mainColor);
    background: #FFF;
    padding: 27px;
    width: 100%;
    display: block;
    h6{
        margin-bottom: 12px;
    }
    p{
        color: #696868;
    }
    &:not(:last-child){
        margin-bottom: 16px;
    }
}
h6{
    color: #262626;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

@media(max-width: 916px){
    .search-page{
        .calendar{
            display: none;
        }
    }
}
@media(max-width: 767px){
    .search-container{
        .flex{
            gap: 10px;
        }
        .flex .main-button{
            padding: 0 14px;
            font-size: 10px;
        }
    }
    .search-card h6{
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
    }
}