.documents-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.document-item{
    border-radius: 6px;
    background: #F5F5F5;
    padding: 16px 10px;
    font-size: 16px;
    color: var(--mainColor);
    display: flex;
    gap: 10px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    transition: .3s;

    span{
        color: #000;
    }

    &:hover{
        box-shadow: 0px 0px 11.6px 2px rgba(60, 171, 225, 0.50);
    }

}
.document-item-date{
    margin-left: auto;
    color: #757575;
    font-size: 12px;
    font-weight: 500;

    i{
        margin-left:10px; 
    }
}