.rating-table{
    border-radius: 6px;
    border-bottom: 6px solid #053A71;
}
.rating-grid{
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;

    thead{
        background: var(--mainColor);
    }
    th{
        color: #FFF;
        font-weight: 400;
        border: none;
        text-align: start;
        padding: 15px;

        &:nth-child(1){
            border-top-left-radius: 6px;
        }
        &:last-child{
            border-top-right-radius: 6px;
        }
    }
    td{
        color: #262626;
        padding: 15px;
    }
}
.bold{
    font-weight: 600;
}