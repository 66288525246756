.categories{
    border-radius: 6px;
    border-bottom: 4px solid var(--mainColor);
    //background: #F7F7F7;
    background: #fff;
    box-shadow: 0px 15px 16px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
    padding: 30px 20px;
}

.hr{
    background: #CCC;
    height: 1px;
    width: 100%;
    margin: 20px 0;
}

.categories-list{
    display: flex;
    flex-direction: column;
    padding: 0;

    a{
        color: #262626;
        font-family: Lato;
        font-size: 20px;
        padding: 12px 16px;
        border-radius: 6px;
        cursor: pointer;


        &:hover, &.active-item{
            background: #FFF;
            color: var(--mainColor);
        }
    }
}
.categories-list-2{
    a{
        &.active{
            background: #FFF;
            color: var(--mainColor);
        }
    }
}

.categories-mob{
    margin: 20px 0 0;
    display: none;
}
@media(max-width: 916px){
    .categories-list{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        a{
            font-size: 14px;
            padding: 6px 12px;
            border-radius: 30px;
            cursor: pointer;

            &.active-item{
                color: #fff;
                background: var(--mainColor);
            }
        }
    }
}