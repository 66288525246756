.custome-pagination{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
}
.pagination{
    display: flex;
    list-style: none;

    li{
        cursor: pointer;
        a{
            display: flex;
            width: 40px;
            height: 40px;
            border: 1px solid #CCC;
            align-items: center;
            justify-content: center;
            background: #fff;
            color: #696868;
        }
        &.active{
            a{
                background: var(--mainColor);
                color: #fff;
                border: 1px solid var(--mainColor);
            }
        }
    }
}